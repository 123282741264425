import {useMutation} from "@tanstack/react-query";
import instance from "../../config/axiosconfig";
import {refreshToken} from "../../utils";

export const useMutationUserAdd = () => {
    return useMutation({
        mutationKey: ["mutateUserAdd"],
        mutationFn: async (addUser) => {
            try {
                const userPayload = { ...addUser, isWebUser: true }; 
                return await instance.post('/users', userPayload);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    await refreshToken();
                    return await instance.post('/users', addUser);
                }
                throw error
            }
        },
    });
};

export const useMutationUpdateUser = (userId) => {
    return useMutation({
        mutationKey: ["updateUser"],
        mutationFn: async (payload) => {
            try {
                return await instance.put('/users/' + userId, payload);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    await refreshToken();
                    return await instance.put('/users/' + userId, payload);
                }
                throw error
            }
        },
    })
}

export const useMutationProfile = (id) => {
    return useMutation({
        mutationKey: ["mutateProfile"],
        mutationFn: async (updateProfile) => {
            try {
                return await instance.put(`/users/${id}`, updateProfile)
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    await refreshToken();
                    return await instance.put(`/users/${id}`, updateProfile)
                }
                throw error
            }
        },
    });
};