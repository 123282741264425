import React from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet"; 
import "leaflet/dist/leaflet.css";
import "leaflet/dist/images/marker-shadow.png";

const markerIconUrl = "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png";

const customIcon = L.icon({
  iconUrl: markerIconUrl,
  iconSize: [30, 50], 
  iconAnchor: [15, 50], 
});

const DetailsMap = ({ location }) => {
  const defaultCenter = location || { lat: 48.8566, lng: 2.3522 };

  return (
    <div style={{ position: "relative", width: "100%", height: "400px", overflow: "hidden" }}>
      <MapContainer
        center={defaultCenter}
        zoom={11}
        style={{ height: "100%", width: "100%" }}
        dragging={true} 
        scrollWheelZoom={true} 
        doubleClickZoom={false} 
        touchZoom={false} 
        keyboard={false} 
        zoomControl={true} 
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        
        <Marker position={defaultCenter} icon={customIcon} />
      </MapContainer>
    </div>
  );
};

export default DetailsMap;