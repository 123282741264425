import * as Yup from 'yup';
import {
	nameRegex,
	emailRegex,
	userNameRegex,
	captionRequired,
	captionPatternName,
	captionPatternEmail,
	captionPatternUserName,
	captionPatternDateEnd,
	captionPatternPasswordMin,
	captionPatternPasswordMax,
	captionPatternPasswordUpper,
	captionPatternPasswordLower,
	captionPatternPasswordNumber,
	captionPatternPasswordMisc,
	captionPatternPasswordCheck,
} from './regex';

// USERS
export const formSchemaUser = Yup.object().shape({
	first_name: Yup.string()
		.required(captionRequired)
		.matches(nameRegex, captionPatternName),

	last_name: Yup.string()
		.required(captionRequired)
		.matches(nameRegex, captionPatternName),

	email: Yup.string()
		.required(captionRequired)
		.matches(emailRegex, captionPatternEmail),

	username: Yup.string()
		.required(captionRequired)
		.matches(userNameRegex, captionPatternUserName),

	passwordOne: Yup.string().when('passwordChange', {
		is: true,
		then: Yup.string()
			.min(8, captionPatternPasswordMin)
			.max(32, captionPatternPasswordMax)
			.matches(/(?=.*[A-Z])/, captionPatternPasswordUpper)
			.matches(/(?=.*[a-z])/, captionPatternPasswordLower)
			.matches(/(?=.*[0-9])/, captionPatternPasswordNumber)
			.matches(/(?=.*[#?!@$%^&*-])/, captionPatternPasswordMisc)
	}),

	passwordTwo: Yup.string().when('passwordChange', {
		is: true,
		then: Yup.string()
			.min(8, captionPatternPasswordMin)
			.max(32, captionPatternPasswordMax)
			.matches(/(?=.*[A-Z])/, captionPatternPasswordUpper)
			.matches(/(?=.*[a-z])/, captionPatternPasswordLower)
			.matches(/(?=.*[0-9])/, captionPatternPasswordNumber)
			.matches(/(?=.*[#?!@$%^&*-])/, captionPatternPasswordMisc)
			.oneOf([Yup.ref('passwordOne')], captionPatternPasswordCheck)
	}),

	birthdayDate: Yup.date()
		.nullable()
		.transform((curr, orig) => (orig === '' ? null : curr)),

	Categories: Yup.array()
		.of(
			Yup.object().shape({
				value: Yup.string().required(),
			})
		)
		.when('Plugins', (plugins, schema) => {
			const hasReportsPlugin = Array.isArray(plugins) && plugins.some((plugin) => plugin.value === 'reports');
	
			return hasReportsPlugin
				? schema.min(1, 'Veuillez sélectionner au moins une catégorie').required('Veuillez sélectionner les catégories pour le module "Signalements"')
				: schema.notRequired().nullable();
	}),
	Plugins: Yup.array().when('role', {
		is: (value) => typeof value === 'string' && ["associate", "manager"].includes(value),
		then: (schema) => 
			schema.of(
				Yup.object({
					value: Yup.string()
				})
			).min(1, captionRequired).required(captionRequired),
		otherwise: (schema) => schema.notRequired()
	})
});

export const formSchemaUserAdd = Yup.object().shape({
    first_name: Yup.string()
        .required(captionRequired)
        .matches(nameRegex, captionPatternName),
    
    last_name: Yup.string()
        .required(captionRequired)
        .matches(nameRegex, captionPatternName),
    
    email: Yup.string()
        .required(captionRequired)
        .matches(emailRegex, captionPatternEmail),

    username: Yup.string()
        .matches(userNameRegex, captionPatternUserName)
        .nullable(),  

    birthdayDate: Yup.date()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr)), 

    // Validation des Plugins
    selectPluginMulti: Yup.array()
        .of(Yup.object({ value: Yup.string().required() }))
        .when('roleChoice', {
            is: (role) => ['associate', 'manager'].includes(role),
            then: (schema) => schema.min(1, captionRequired).required(captionRequired),
            otherwise: (schema) => schema.notRequired().nullable(),
        }),

    // Validation des Catégories
	selectCategoryMulti: Yup.array()
  .of(Yup.object({ value: Yup.string().required() }))
  .when(['selectPluginMulti', 'roleChoice'], (plugins, role, schema) => {
    const hasReportsPlugin = Array.isArray(plugins) && plugins.some((plugin) => plugin.value === 'reports');
    const isEligibleRole = ['associate', 'manager'].includes(role);

    if (hasReportsPlugin && isEligibleRole) {
      return schema.min(1, 'Veuillez sélectionner au moins une catégorie').required();
    }

    return Yup.mixed().notRequired(); // Remplace `schema.transform()` par `Yup.mixed().notRequired()`
  }),
});

// EVENTS
export const formSchemaEventAdd = Yup.object().shape({
	dateStart: Yup.date()
		.nullable()
		.transform((curr, orig) => (orig === '' ? null : curr))
		.required(captionRequired),

	dateEnd: Yup.date()
		.nullable()
		.transform((curr, orig) => (orig === '' ? null : curr))
		.min(Yup.ref('dateStart'), captionPatternDateEnd)
		.required(captionRequired),

	title: Yup.string().required(captionRequired),

	content: Yup.string().required(captionRequired)
});

// REPORTS ADD
export const formSchemaIssueAdd = Yup.object().shape({
	dateStart: Yup.date()
		.nullable()
		.transform((curr, orig) => (orig === '' ? null : curr))
		.required(captionRequired),

	dateEnd: Yup.date()
		.nullable()
		.transform((curr, orig) => (orig === '' ? null : curr))
		.min(Yup.ref('dateStart'), captionPatternDateEnd)
		.required(captionRequired),

	title: Yup.string().required(captionRequired),

	description: Yup.string().required(captionRequired)
});

// REPORTS EDIT
export const formSchemaIssueEdit = Yup.object().shape({
	selectStatus: Yup.string().required(captionRequired),

	selectCategory: Yup.string()
		.required(captionRequired)
		.typeError(captionRequired),

	dateStart: Yup.date()
		.nullable()
		.transform((curr, orig) => (orig === '' ? null : curr))
		.required(captionRequired),

	dateEnd: Yup.date()
		.nullable()
		.transform((curr, orig) => (orig === '' ? null : curr))
		.min(Yup.ref('dateStart'), captionPatternDateEnd),

	title: Yup.string().required(captionRequired),

	description: Yup.string().required(captionRequired),

});

// NEWS
export const formSchemaNewsAdd = Yup.object().shape({
	title: Yup.string().required(captionRequired),
	// section: Yup.object().shape({
	// 	value: Yup.string().required()}),
	content: Yup.string().required(captionRequired)
});

// ADD CONTACT TO DIRECTORY
export const formSchemaAddContact = Yup.object().shape({
	group: Yup.string().matches(nameRegex, captionPatternName),
	role: Yup.string()
		.required(captionRequired)
		.matches(nameRegex, captionPatternName),
	surname: Yup.string()
		.required(captionRequired)
		.matches(nameRegex, captionPatternName),
	name: Yup.string()
		.required(captionRequired)
		.matches(nameRegex, captionPatternName),
});

// ADD JOB
export const formSchemaAddJob = Yup.object().shape({
	startTime: Yup.date()
		.nullable()
		.transform((curr, orig) => (orig === '' ? null : curr))
		.required(captionRequired),
	contractType: Yup.string().required(captionRequired),
	workTime: Yup.string().required(captionRequired),
	entrepriseName: Yup.string().required(captionRequired),
	title: Yup.string().required(captionRequired),
	//category: Yup.string().required(captionRequired),
	address: Yup.string().required(captionRequired),
	description: Yup.string().required(captionRequired)
});

// SURVEY
export const formSchemaAddSurvey = Yup.object({
	title: Yup.string().required(captionRequired),
	description: Yup.string().required(captionRequired),
	// fieldArray: Yup.array().of(
	// 	Yup.object({
	// 		textInput: Yup.string().required(captionRequired),
	// 		textareaInput: Yup.string().required(captionRequired),
	// 		selectInput: Yup.string().required(captionRequired),
	// 		checkboxInput: Yup.string().required(captionRequired)
	// 	})
	// ),
	// selectAdd: Yup.array().of(
	// 	Yup.object().shape({
	// 		input: Yup.string().required(captionRequired)
	// 	})
	// ),
	// checkboxAdd: Yup.array().of(
	// 	Yup.object().shape({
	// 		nestedArray: Yup.string().required(captionRequired)
	// 	})
	// )
});

// FORUM ADD
export const formSchemaForumAdd = Yup.object().shape({
	title: Yup.string().required(captionRequired),
	moderators: Yup.array()
		.of(Yup.object().shape({
			id: Yup.string().required(),
			username: Yup.string().required(),
		}))
		.min(1, 'Veuillez sélectionner au moins un modérateur')
		.required('Au moins un modérateur est requis'),
});

// COMMENTS
export const formCommentsAdd = Yup.object().shape({
	comment: Yup.string().required(captionRequired)
});
