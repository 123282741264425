// External librairies
import {useMutation} from "@tanstack/react-query";

// Instance
import instance from "../../config/axiosconfig";
import {refreshToken} from "../../utils";

export const useMutationJobAdd = () => {
    return useMutation({
        mutationKey: ["mutateJobAdd"],
        mutationFn: async (addJob) => {
            try {
                return await instance.post('/jobs', addJob);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    await refreshToken();
                    return await instance.post('/jobs', addJob);
                }
                throw error
            }
        },
    });
};

export const useMutationJobDelete = () => {
    return useMutation({
        mutationKey: ["deleteJob"],
        mutationFn: async (id) => {
            try {
                return await instance.delete(`/jobs/${id}`);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    await refreshToken();
                    return await instance.delete(`/jobs/${id}`);
                }
                throw error
            }
        },
    });
};

export const useMutationJobUpdate = (id) => {
    return useMutation({
        mutationKey: ["updateJob"],
        mutationFn: async (updateJob) => {
            
            try {
                return await instance.put(`/jobs/${id}`, updateJob);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    await refreshToken();
                    return await instance.put(`/jobs/${id}`, updateJob);
                }
                throw error
            }
        },
    });
};