// External libraries
import { useContext } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { AuthContext } from "../contexts";
import ProfileCard from "../components/ProfileCard/ProfileCard";
import { Icon } from '@mui/material';
import { Menu, ChevronLeft, Logout } from "@mui/icons-material";
import { slugToIcon } from "../config/formats";

const NavigationLink = ({ title, path, isAdmin = false, isOpen }) => {
    return (
        <NavLink
			className={(navData) =>
				navData.isActive
					? isAdmin
						? "w-full border flex items-center gap-x-3 p-1.5 px-[0.44em] rounded-md bg-roles-admin border-roles-admin text-textNeutral-light"
						: "w-full border flex items-center gap-x-3 p-1.5 px-[0.44em] rounded-md bg-primary border-primary text-textNeutral-light"
					: isAdmin
					? "w-full border border-transparent flex items-center gap-x-3 p-1.5 px-[0.44em] rounded-md hover:bg-roles-admin/[.12] hover:text-roles-admin hover:border-roles-admin transition-all"
					: "w-full border border-transparent flex items-center gap-x-3 p-1.5 px-[0.44em] rounded-md hover:bg-primary/[.12] hover:text-primary hover:border-primary transition-all"
			}
			to={path}
		>
			<Icon className={"text-inherit"} sx={{ fontSize: 18 }}>{slugToIcon[path]}</Icon>
			<span
				className={classNames(
					"flex text-[12px] font-medium text-inherit origin-left transition-all whitespace-nowrap transition-opacity duration-500",
					!isOpen && "opacity-0 invisible 2xl:block"
				)}
			>
				{title}
			</span>
		</NavLink>
    );
};

const SideBar = ({ setIsOpen, isOpen }) => {
    const { logout, user } = useContext(AuthContext);

    const sidebarLinks = [
        { title: "Tableau de bord", path: "/", isAllowed: true },
        { title: "Incidents", path: "reports", isAllowed: true },
        { title: "Nouvelles", path: "news", isAllowed: process.env.REACT_APP_PLUGIN_NEWS === 'true' },
        { title: "Agenda", path: "agenda", isAllowed: process.env.REACT_APP_PLUGIN_AGENDA === 'true' },
        { title: "Annuaire", path: "directory", isAllowed: process.env.REACT_APP_PLUGIN_DIRECTORY === 'true' },
        { title: "Emplois", path: "job", isAllowed: process.env.REACT_APP_PLUGIN_JOB === 'true' },
        { title: "Questionnaires", path: "survey", isAllowed: process.env.REACT_APP_PLUGIN_SURVEY === 'true' },
        { title: "Forum", path: "forum", isAllowed: process.env.REACT_APP_PLUGIN_FORUM === 'true' },
    ];

    return (
        <div className="relative shadow-custom bg-bgNeutral-light dark:bg-bgNeutral-elementdark rounded-lg w-full h-[95%] pt-4 px-3 flex flex-col overflow-auto">
            <div className={classNames("flex", isOpen ? "justify-between" : "justify-center")}> 
                <h1 className={classNames("text-sm font-bold text-gray-800 dark:text-gray-200", !isOpen && "hidden")}>Smapi</h1>
                <button onClick={() => setIsOpen(!isOpen)} className="p-2 rounded-md bg-gray-200 dark:bg-gray-700">
                    {isOpen ? <ChevronLeft sx={{ fontSize: 18 }} /> : <Menu />}
                </button>
            </div>
            
            <div className="mt-8">
                <ul className="flex flex-col gap-y-2">
                    {sidebarLinks.filter(link => link.isAllowed).map((link, index) => (
                        (user?.activePlugins?.includes(link.path) || user?.role === "ROLE_ADMIN" || link.path === "/") && (
                            <li key={index}>
                                <NavigationLink title={link.title} path={link.path} isOpen={isOpen} />
                            </li>
                        )
                    ))}
                    {user?.role === "ROLE_ADMIN" && (
                        <div className="mt-2 pt-5 border-t border-bgNeutral-dark/[.22] dark:border-bgNeutral-light/[.22] flex">
                            <NavigationLink
								className="text-textNeutral-light"
								title="Utilisateurs"
								icon="users"
								path="users"
								isAdmin={true}
								isOpen={isOpen}
							/>
                        </div>
                    )}
                </ul>
            </div>
            
            <div className="flex flex-col gap-y-5 mt-auto mb-4">
                <NavLink to={`/profile/${user?.id}/brief`}>
                    <ProfileCard isOpen={isOpen} user={user} />
                </NavLink>
                <button onClick={logout} className="flex items-center gap-x-2 text-sm text-gray-800 dark:text-gray-200">
                    <Logout sx={{ fontSize: 18 }} />
                    <span
							className={classNames(
								"transition-opacity duration-500",
								!isOpen && "opacity-0 invisible 2xl:block"
							)}
						>
							Déconnexion
						</span>
                </button>
            </div>
        </div>
    );
};

export default SideBar;
