import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet/dist/images/marker-shadow.png";

const markerIconUrl = "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png";

const CenterMarker = ({ setFinalPosition, onChange }) => {
  const map = useMap();

  useEffect(() => {
    const onMoveEnd = () => {
      const newCenter = map.getCenter();
      const newPosition = { lat: newCenter.lat, lng: newCenter.lng }; // Ensure correct format
      setFinalPosition(newPosition);
      if (onChange) {
        onChange(newPosition); 
      }
    };

    map.on("moveend", onMoveEnd);
    return () => map.off("moveend", onMoveEnd);
  }, [map, setFinalPosition, onChange]);

  return null;
};

const SimpleMap = ({ location, onChange, disableScroll = false }) => {
  const defaultCenter = location || { lat: 48.8566, lng: 2.3522 }; 
  const [finalPosition, setFinalPosition] = useState(defaultCenter);

  return (
    <div style={{ position: "relative", width: "100%", height: "400px", overflow: "hidden" }}>
      <MapContainer
        center={finalPosition}
        zoom={11}
        style={{ height: "100%", width: "100%" }}
        dragging={!disableScroll} 
        scrollWheelZoom={true} 
        doubleClickZoom={true} 
        touchZoom={true} 
        keyboard={!disableScroll} 
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <CenterMarker setFinalPosition={setFinalPosition} onChange={onChange} />
      </MapContainer>

      <img
        src={markerIconUrl}
        alt="Center Marker"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -100%)",
          width: "30px",
          height: "50px",
          zIndex: 1000, 
          pointerEvents: "none",
          display: "block", 
        }}
        onError={() => console.error("Marker image failed to load!")}
      />
    </div>
  );
};

export default SimpleMap;