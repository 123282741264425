// Routes path
import {appRoutes, appRoutesSub} from "./routes";
import socketIO from 'socket.io-client';

// Route elements
import {
    LoginPage,
    RecoverPasswordPage,
    UpdatePasswordPage,
    NewUserLoginPage,
    MainInterface,
    HomePage,
    IssuesPageTable,
    IssuesPageSelect,
    IssuesPageBrief,
    IssuesPageEdit,
    IssuesPageCreate,
    IssuesPageComments,
    NewsPage,
    NewsPageSelect,
    NewsPageBrief,
    NewsPageEdit,
    NewsPageCreate,
    AgendaPage,
    AgendaPageSelect,
    AgendaPageBrief,
    AgendaPageEdit,
    AgendaPageCreate,
    DirectoryPage,
    DirectoryPageSelect,
    DirectoryPageBrief,
    DirectoryPageEdit,
    DirectoryPageCreateUser,
    JobPage,
    JobPageCreate,
    JobPageEdit,
    JobPageSelect,
    SurveyPage,
    SurveyPageBrief,
    SurveyPageCreate,
    SurveyPageEdit,
    SurveyPageSelect,
    UsersPageTable,
    UsersPageSelect,
    UsersPageBrief,
    UsersPageEdit,
    UsersPageCreate,
    ProfilePageSelect,
    ProfilePageBrief,
    ProfilePageEdit,
    NotificationsPage,
    NotFound,
    ForumPageSettings,
    ForumPageEdit,
    ForumPageBrief,
    ForumPageSelect,
    ForumPage,
    ForumPageCreate,
    SurveyPageExport
} from '../pages';

// Routes restrictions
import {PrivateRoute} from "../utils";
import {BASE_URL} from "../config/config";

const SOCKET_SERVER_URL = BASE_URL.replace(/\/api$/, "");
const socket = socketIO.connect(SOCKET_SERVER_URL);

export const routesConfig = [
    {
        path: appRoutes.LOGIN,
        element: <LoginPage/>
    },
    {
        path: appRoutes.FIRST_LOGIN,
        element: <NewUserLoginPage/>
    },
    {
        path: appRoutes.FORGOT_PASSWORD,
        element: <RecoverPasswordPage/>
    },
    {
        path: appRoutes.RECOVERY_PASSWORD,
        element: <UpdatePasswordPage/>
    },
    {
        path: appRoutes.MAIN,
        element: (
            <PrivateRoute>
                <MainInterface/>
            </PrivateRoute>
        ),
        children: [
            {
                element: <HomePage/>,
                index: true
            },
            {
                path: appRoutes.REPORTS,
                element: <IssuesPageTable/>
            },
            {
                path: appRoutes.REPORTS_ID,
                element: <IssuesPageSelect/>,
                children: [
                    {
                        path: appRoutesSub.BRIEF,
                        element: <IssuesPageBrief/>
                    },
                    {
                        path: appRoutesSub.EDIT,
                        element: <IssuesPageEdit/>
                    },
                    {
                        path: appRoutesSub.COMMENTS,
                        element: <IssuesPageComments socket={socket}/>,
                    },
                ],
            },
            {
                path: appRoutes.REPORTS_CREATE,
                element: <IssuesPageCreate/>
            },
            {
                path: appRoutes.NEWS,
                element: <NewsPage/>
            },
            {
                path: appRoutes.NEWS_ID,
                element: <NewsPageSelect/>,
                children: [
                    {
                        path: appRoutesSub.BRIEF,
                        element: <NewsPageBrief/>
                    },
                    {
                        path: appRoutesSub.EDIT,
                        element: <NewsPageEdit/>
                    }
                ]
            },
            {
                path: appRoutes.NEWS_CREATE,
                element: <NewsPageCreate/>
            },
            {
                path: appRoutes.AGENDA,
                element: <AgendaPage />
            },
            {
                path: appRoutes.AGENDA_ID,
                element: <AgendaPageSelect/>,
                children: [
                    {
                        path: appRoutesSub.BRIEF,
                        element: <AgendaPageBrief/>
                    },
                    {
                        path: appRoutesSub.EDIT,
                        element: <AgendaPageEdit/>
                    }
                ]
            },
            {
                path: appRoutes.AGENDA_CREATE,
                element: <AgendaPageCreate/>
            },
            {
                path: appRoutes.DIRECTORY,
                element: <DirectoryPage/>
            },
            {
                path: appRoutes.DIRECTORY_ID,
                element: <DirectoryPageSelect/>,
                children: [
                    {
                        path: appRoutesSub.BRIEF,
                        element: <DirectoryPageBrief/>
                    },
                    {
                        path: appRoutesSub.EDIT,
                        element: <DirectoryPageEdit/>
                    }
                ]
            },
            {
                path: appRoutes.DIRECTORY_CREATE_USER,
                element: <DirectoryPageCreateUser/>
            },
            {
                path: appRoutes.JOB,
                element: <JobPage/>
            },
            {
                path: appRoutes.JOB_ID,
                element: <JobPageSelect/>,
                children: [
                    {
                        path: appRoutesSub.EDIT,
                        element: <JobPageEdit/>
                    }
                ]
            },
            {
                path: appRoutes.JOB_CREATE,
                element: <JobPageCreate/>
            },
            {
                path: appRoutes.SURVEY,
                element: <SurveyPage/>
            },
            {
                path: appRoutes.SURVEY_ID,
                element: <SurveyPageSelect/>,
                children: [
                    {
                        path: appRoutesSub.BRIEF,
                        element: <SurveyPageBrief/>
                    },
                    {
                        path: appRoutesSub.EDIT,
                        element: <SurveyPageEdit/>
                    },
                    {
                        path: appRoutesSub.EXPORT,
                        element: <SurveyPageExport/>
                    },
                ]
            },
            {
                path: appRoutes.SURVEY_CREATE,
                element: <SurveyPageCreate/>
            },
            {
                path: appRoutes.FORUM,
                element: <ForumPage/>
            },
            {
                path: appRoutes.FORUM_ID,
                element: <ForumPageSelect/>,
                children: [
                    {
                        path: appRoutesSub.BRIEF,
                        element: <ForumPageBrief socket={socket}/>
                    },
                    {
                        path: appRoutesSub.EDIT,
                        element: <ForumPageEdit/>
                    },
                    {
                        path: appRoutesSub.SETTINGS,
                        element: <ForumPageSettings/>
                    }
                ]
            },
            {
                path: appRoutes.FORUM_CREATE,
                element: <ForumPageCreate/>
            },
            {
                path: appRoutes.USERS,
                element: <UsersPageTable/>
            },
            {
                path: appRoutes.USERS_ID,
                element: <UsersPageSelect/>,
                children: [
                    {
                        path: appRoutesSub.BRIEF,
                        element: <UsersPageBrief/>
                    },
                    {
                        path: appRoutesSub.EDIT,
                        element: <UsersPageEdit/>
                    }
                ]
            },
            {
                path: appRoutes.USERS_CREATE,
                element: <UsersPageCreate/>
            },
            {
                path: appRoutes.PROFILE, // "profile/:id"
                element: <ProfilePageSelect />,
                children: [
                    {
                        path: appRoutesSub.BRIEF, // "brief"
                        element: <ProfilePageBrief />
                    },
                    {
                        path: appRoutesSub.EDIT, // "edit"
                        element: <ProfilePageEdit />
                    }
                ]
            },
            {
                path: appRoutes.NOTIFICATIONS,
                element: <NotificationsPage/>
            }
        ]
    },
    {
        path: appRoutesSub.UNKNOWN,
        element: <NotFound/>
    }
];
