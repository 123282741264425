import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// Query
import { useMutationLogin } from "../../queries";

// Components
import { FormButton } from "../../components/Button";
import { ErrorMessage } from "../../components/Errors";

// CSS
import 'react-toastify/dist/ReactToastify.css';

// Material UI Icons
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Custom styles
const labelStyle =
	"mb-2 text-primary text-xs font-semibold uppercase tracking-tighter opacity-75 pl-3";
const inputStyle =
	"border-none bg-slate-200 rounded-lg px-4 py-3 text-xs transition-all outline-none"; // Removed focus styles from here

const inputStyleName =
	"border-none bg-slate-200 rounded-lg px-4 py-3 text-xs transition-all outline-none focus:border-sky-500 focus:ring-2 focus:ring-primary";

	
const LoginPage = () => {
	const { mutate } = useMutationLogin();
	const {
		register,
		formState: { errors, isValid },
		handleSubmit,
	} = useForm({
		mode: "onChange",
	});

	// State to manage password visibility and focus styling
	const [passwordVisible, setPasswordVisible] = useState(false);
	const [focus, setFocus] = useState(false); // To manage focus state

	const handleLogin = (data, e) => {
		e.preventDefault();
		const { username, password } = data;
		const credentials = { username, password };

		mutate(credentials);
	};

	return (
		<div className="bg-gradient-to-l from-primary to-secondary w-full h-[100vh] flex justify-center items-center">
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover={false}
				theme="colored"
			/>
			<div className="md:mx-0 md:shadow-xl w-full md:w-[860px] h-full md:h-[520px] flex flex-col justify-end md:flex-row">
				<div className="relative md:bg-gradient-to-br md:from-primary md:to-secondary w-full md:h-auto md:w-1/2 pb-10 md:pb-10 md:px-8 flex justify-center items-center">
					<span className="absolute hidden md:block md:bottom-5 md:right-5 uppercase text-white font-bold text-xs">
						SMAPI
					</span>
					<div className="flex justify-center items-center">
						<figure className="w-32 md:w-56">
							<img
								className="w-full h-auto"
								src={`${process.env.REACT_APP_API_URL}/uploads/logo.png`}
								alt="city img"
							/>
						</figure>
					</div>
				</div>
				<div className="bg-white rounded-t-[2em] md:rounded-t-none w-full md:w-1/2 h-2/3 md:h-auto flex flex-col justify-around">
					<div className="mx-8 md:mx-12">
						<div className="flex justify-center mb-10 md:mb-5">
							<h2 className="font-semibold text-textNeutral-dark">
								Connectez-vous
							</h2>
						</div>
						<form
							className="flex flex-col"
							onSubmit={(e) => {
								handleSubmit(handleLogin)(e);
							}}
						>
							<div className="mb-4 flex flex-col">
								<label className={labelStyle}>
									Nom d'utilisateur
								</label>
								<input
									className={inputStyleName}
									{...register("username", {
										required: true,
									})}
									placeholder="Nom d'utilisateur"
								/>
								{errors?.username && (
									<ErrorMessage message="Veuillez entrer votre nom d'utilisateur" />
								)}
							</div>
							<div className="mb-4 flex flex-col">
								<label className={labelStyle}>
									Mot de passe
								</label>
								{/* Updated div to apply primary color on focus */}
								<div
									className={`relative bg-slate-200 rounded-lg transition-all ${focus ? "focus-within:ring-2 focus-within:ring-primary" : ""}`}
								>
									<input
										className={`${inputStyle} pr-10`} // Added padding-right for the icon
										{...register("password", {
											required: true,
										})}
										type={passwordVisible ? "text" : "password"}
										placeholder="Mot de passe"
										onFocus={() => setFocus(true)} // Set focus to true on input focus
										onBlur={() => setFocus(false)} // Set focus to false on input blur
									/>
									<span
										className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
										onClick={() => setPasswordVisible(!passwordVisible)}
									>
										{passwordVisible ? (
											<VisibilityOff fontSize="small" />
										) : (
											<Visibility fontSize="small" />
										)}
									</span>
								</div>
								{errors?.password && (
									<ErrorMessage message="Veuillez entrer votre mot de passe" />
								)}
							</div>
							<div>
								<div className="w-full flex justify-end">
									<NavLink
										className="text-xs text-primary"
										to="/forgot-password"
									>
										Mot de passe oublié ?
									</NavLink>
								</div>
								<div className="mt-10 md:mt-5 flex justify-center">
									<FormButton
										caption="Se connecter"
										isValid={isValid}
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoginPage;