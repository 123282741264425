// External libraries
import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { TextFields, TextFormat, FilterList, Check } from '@mui/icons-material';
import { Transition } from '../../utils/';

const FieldTypeComponents = {
	text: { icon: TextFields, label: 'Réponse courte' },
	textarea: { icon: TextFormat, label: 'Paragraphe' },
	select: { icon: FilterList, label: 'Liste sélective' },
	checkbox: { icon: Check, label: 'Choix multiple' }
};

const FieldHeader = ({ type }) => {
	const { icon: Icon, label } = FieldTypeComponents[type];
	return (
		<div className="border-b-[1px] border-bgNeutral-dark/[.15] flex items-center gap-x-3 pb-3">
			<i className="opacity-50">
				<Icon className="block w-4 h-4 text-textNeutral-dark" />
			</i>
			<h3 className="opacity-[.55]">{label}</h3>
		</div>
	);
};

const renderOptions = (options, Component) => (
	<ul className="mt-5 flex items-center gap-x-5">
		{JSON.parse(options).map((option, i) => (
			<Component key={i} option={option} />
		))}
	</ul>
);

const SelectOption = ({ option }) => (
	<li className="rounded-[6px] px-8 py-2 text-[13px] text-textNeutral-dark/[.75] bg-bgNeutral-dark/[.08]">
		{option}
	</li>
);

const CheckboxOption = ({ option }) => (
	<li className="pl-4 relative before:content-[''] before:rounded-full before:w-[6px] before:h-[6px] before:absolute before:top-1/2 before:left-0 before:-translate-y-1/2 before:bg-bgNeutral-dark/[.25]">
		{option}
	</li>
);

const Field = ({ field }) => {
	const { type, question, options } = field;
	
	const renderFieldOptions = () => {
		if (type === 'select') return renderOptions(options, SelectOption);
		if (type === 'checkbox') return renderOptions(options, CheckboxOption);
		return null;
	};

	return (
		<div className="w-full flex flex-col gap-y-5">
			<FieldHeader type={type} />
			<div>
				<h4>{question}</h4>
				{renderFieldOptions()}
			</div>
		</div>
	);
};

function formatResponseValue(response) {
    if (!response || typeof response !== 'object') {
        return "Invalid response format.";
    }

    const { user, value } = response;
   
    let result = '';

    if (Array.isArray(value)) {
        result += "Values:\n";
        value.forEach((val, index) => {
            result += `${index + 1}. ${val}\n`;
        });
    } else if (typeof value === 'string') {
        result += `${value}`;
    } else {
        return "Invalid 'value' format. Expected string or array.";
    }

    return result.trim(); // Remove trailing newline
}


const SurveyResponse = ({ response }) => {
	
	if(response === null) return <p>Aucune réponse</p>;
	
	const user = response.user
	
	return (
		<li className="rounded-[8px] border-[1px] border-primary-light p-4 bg-white shadow-md">
			<p className="font-semibold">User: {user}</p>
			<p>
				Réponses:{" "}
				{formatResponseValue(response)}
			</p>
		</li>
	);
};

const SurveyPageBrief = () => {
	const surveyData = useOutletContext();

	return (
		<Transition>
			<div className="w-full lg:w-[70%] flex flex-col gap-y-8">
				<div>
					<h2>{surveyData.title}</h2>
				</div>
				<ul className="rounded-[12px] flex flex-col gap-y-8">
					{surveyData.fieldArray.map((field) => (
						<li key={field.id} className="rounded-[12px] border-[1.5px] border-primary/[.55] p-10 bg-bgNeutral-light">
							<div className="w-full flex flex-col gap-y-5">
								<Field field={field} />
								{field.SurveyResponses?.length > 0 && (
									<div className="rounded-[12px] border-[1.5px] border-secondary/[.55] p-5 bg-bgNeutral-lighter">
										<h3>Survey Responses</h3>
										<ul className="flex flex-col gap-y-4">
											{field.SurveyResponses.map((response, index) => (
												<SurveyResponse key={index} response={response} />
											))}
										</ul>
									</div>
								)}
							</div>
						</li>
					))}
				</ul>
			</div>
		</Transition>
	);
};

export default SurveyPageBrief;