import React, { useEffect, useCallback,useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useMutationUserAdd, useQueryCategories, useQueryPlugins } from "../../queries";
import { NavigationHeader, TextInput, RadioInput, SelectMultiInput, FormButton } from "../../components";
import { ROLES } from "../../config/config";
import { formSchemaUserAdd } from "../../config/yupconfig";
import { Transition } from "../../utils/react-router";
import { randomPassword } from "../../config/methods";
import { data, useNavigate } from "react-router-dom";

const UsersPageCreate = () => {
    const navigate = useNavigate();
    const { mutate } = useMutationUserAdd();
    const { data: categories = [] } = useQueryCategories();
    const { data: plugins = [] } = useQueryPlugins();

    const {
        register,
        handleSubmit,
        control,
        watch,
        formState: { errors, isValid },
        reset,
    } = useForm({
        resolver: yupResolver(formSchemaUserAdd),
        defaultValues: {
            roleChoice: "citizen",
            selectCategoryMulti: [],
            selectPluginMulti: [],
        },
        mode: "onChange",
    });

    const categoryOptions = useMemo(() => 
        categories.map(c => ({ value: c.value, label: c.name })),
        [categories]
    );

    const pluginOptions = useMemo(() =>
        plugins.map(p => ({ value: p.value, label: p.name })),
        [plugins]
    );

    const transformSelection = (selected, options) =>
        selected.map(id => options.find(opt => opt.value === id));

    const watchUserRole = watch("roleChoice");
    const watchUserPlugin = watch("selectPluginMulti", []);

    // Reset default values on component mount
    useEffect(() => {
        reset({
            roleChoice: "citizen",
            selectCategoryMulti: [],
            selectPluginMulti: [],
        });
    }, [reset]);

    const onSubmit = useCallback(
       
        
        (data, e) => {
            console.log(JSON.stringify(data));
            e.preventDefault();
            const formattedData = {
                birthday: new Date(),
                username: `newUser-${Date.now()}`,
                password: randomPassword(12),
                role: data.roleChoice,
                ccategories: data.selectCategoryMulti,
                plugins: data.selectPluginMulti,
                ...data,
            };

            mutate(formattedData, {
                onSuccess: (res) => {
                    toast.success("L'utilisateur a été créé !");
                    navigate(`/users/${res.data.data.id}/brief`);
                },
                onError: () => toast.error("Une erreur est survenue"),
            });
        },
        [mutate, navigate,categoryOptions, pluginOptions]
    );

    return (
        <Transition>
            <div className="w-full lg:w-[46rem] flex flex-col gap-y-5">
                <NavigationHeader title="Utilisateurs" subTitle="Ajouter un utilisateur" returnUrl="/users" />
                <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-col gap-y-6 md:gap-y-10">
                        {/* First Name & Last Name */}
                        <div className="flex flex-col gap-y-8 md:flex-row justify-between">
                            <TextInput id="first_name" label="Prénom" register={register("first_name")} error={errors?.first_name} />
                            <TextInput id="last_name" label="Nom de famille" register={register("last_name")} error={errors?.last_name} />
                        </div>

                        {/* Email & Plugins */}
                        <div className="flex flex-col gap-y-8 md:flex-row justify-between">
                            <TextInput id="email" label="Email" register={register("email")} error={errors?.email} />
                            {(["associate", "manager"].includes(watchUserRole)) && (
                                <Controller
                                    name="selectPluginMulti"
                                    control={control}
                                    render={({ field }) => (
                                        <SelectMultiInput placeholder="Modules" options={plugins} field={field} label="Modules" error={errors?.selectPluginMulti} />
                                    )}
                                />
                            )}
                        </div>

                        {/* Categories */}
                        {watchUserPlugin.some((p) => p.value === "reports") && ["associate", "manager"].includes(watchUserRole) && (
                            <Controller
                                name="selectCategoryMulti"
                                control={control}
                                render={({ field }) => (
                                    <SelectMultiInput 
                                        placeholder="Catégories" 
                                        options={categories} 
                                        field={field} 
                                        label="Catégories" 
                                        error={errors?.selectCategoryMulti} 
                                    />
                                )}
                            />
                        )}

                        {/* Roles */}
                        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-y-6 gap-x-3 md:gap-x-4">
                            {ROLES.filter((v) => v.slug !== "all").map((role) => (
                                <RadioInput 
                                    key={role.id} 
                                    id={role.slug} 
                                    label={role.name} 
                                    register={register("roleChoice")} 
                                    value={role.slug} 
                                    bg={role.bg}
                                    bgFade={role.bgFade}
                                    border={role.border}
                                    color={role.color}
                                    ring={role.ring}/>
                            ))}
                        </div>

                        {/* Submit Button */}
                        <div className="mt-5 w-full flex justify-end">
                            <FormButton caption="Ajouter l'utilisateur" icon="user" isValid={isValid} />
                        </div>
                    </div>
                </form>
            </div>
        </Transition>
    );
};

export default UsersPageCreate;
