// External libraries
import { useMemo, useState } from "react";

// Query
import { useQueryIssuesTable, useQueryCategories } from "../../queries";

// Hooks
import { UseTableWithColumns } from "../../hooks";

// Components
import {SelectListFilter, EditTableButton, NoData} from "../../components";

// Config
import { STATUS, ROLES } from "../../config/config";
import { formatDateString } from "../../config/formats";

// Misc
import { Loader, Transition } from "../../utils";

//Icon
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const TableReports = () => {
    const [categoriesFilter, setCategoriesFilter] = useState(["all"]);
    const [statusFilter, setStatusFilter] = useState("all");
    const [currentPage, setCurrentPage] = useState(1);
    const [sort, setSort] = useState({
        direction: "asc",
        accessor: "id",
    });

    const { isLoading, data } = useQueryIssuesTable(
        currentPage,
        statusFilter,
        categoriesFilter,
        sort
    );
    
    const { isLoading: isLoadingCategories, data: categories } = useQueryCategories();

    const columns = useMemo(() => [
        {
            accessor: "action",
            Cell: ({ row: { original } }) => <EditTableButton id={original.id} />,
        },
        {
            Header: "N°",
            accessor: "id",
            sortDirection: sort.accessor === "id" ? sort.direction : "none",
            Cell: ({ value }) => <span className="text-textNeutral-dark">{value}</span>,
        },
        {
            Header: "Sujet",
            accessor: "title",
            sortDirection: sort.accessor === "title" ? sort.direction : "none",
            Cell: ({ value }) => <span className="text-textNeutral-dark">{value}</span>,
        },
        {
            Header: "Soumis par",
            accessor: "user",
            sortDirection: sort.accessor === "user" ? sort.direction : "none",
            Cell: ({ row: { original } }) => (
                <span className="text-textNeutral-dark dark:text-textNeutral-light">
                    {original.Creator?.username}
                </span>
            ),
        },
        {
            Header: "Rôle",
            accessor: "role",
            Cell: ({ row: { original } }) => {
                const translatedRole = ROLES.find((r) => r.slug === original.Creator?.role);
                return (
                    <span className="text-textNeutral-dark dark:text-textNeutral-light">
                        {translatedRole?.name}
                    </span>
                );
            },
        },
        {
            Header: "Assigné à",
            accessor: "assigned_to",
            Cell: ({ row: { original } }) => (
                <span className="text-textNeutral-dark dark:text-textNeutral-light">
                    {original.Assignee?.username || "Aucun"}
                </span>
            ),
        },
        {
            Header: "Etat",
            accessor: "status",
            sortDirection: sort.accessor === "status" ? sort.direction : "none",
            Cell: ({ row: { original } }) => {
                const translatedStatus = STATUS.find((status) => status.slug === original.status);
                return (
                    <span className={`rounded-full border ${translatedStatus.border} flex justify-center items-center px-2 py-1 text-center text-[8px] font-semibold ${translatedStatus.bgFade} ${translatedStatus.color} uppercase overflow-hidden whitespace-nowrap`}>
                        {translatedStatus.name}
                    </span>
                );
            },
        },
        {
            Header: "Date de création",
            accessor: "rep_created",
            sortDirection: sort.accessor === "rep_created" ? sort.direction : "none",
            Cell: ({ value }) => formatDateString(value),
        },
        {
            Header: "Heure",
            accessor: "hour",
            Cell: ({ row: { original } }) => {
                const date = new Date(original.rep_created);
                const hours = date.getHours().toString().padStart(2, '0');
                const minutes = date.getMinutes().toString().padStart(2, '0');
                return `${hours}:${minutes}`;
            },
        },
        {
            Header: "Modifié le",
            accessor: "rep_updated",
            sortDirection: sort.accessor === "rep_updated" ? sort.direction : "none",
            Cell: ({ value }) => formatDateString(value),
        },
        {
            Header: "Catégorie",
            accessor: "category",
            sortDirection: sort.accessor === "category" ? sort.direction : "none",
            Cell: ({ row: { original } }) => {
                const icon = categories?.find((cat) => cat.value === original.category)?.icon;
                const translate = categories?.find((cat) => cat.value === original.category)?.label;
                return (
                    <div className="flex items-center gap-x-2">
                        <i>{icon}</i>
                        <span className="text-textNeutral-dark dark:text-textNeutral-light">
                            {translate}
                        </span>
                    </div>
                );
            },
            
        },
        {
            Header: "Interne",
            accessor: "internal",
            sortDirection: sort.accessor === "internal" ? sort.direction : "none",
            Cell: ({ row: { original } }) => {
                console.log(original.isInternal);
                
                const isInternal = original.isInternal;

                const icon = isInternal ? <CheckCircleIcon className="text-green-500" /> : <CancelIcon className="text-red-500" />;

                return (
                    <div className="flex items-center gap-x-2">
                        <i>{icon}</i>
                        
                    </div>
                );
            },
            
        },
    ], [sort, categories]);

    return (
        <>
            {isLoading || isLoadingCategories ? (
                <Loader />
            ) : (
                <div className="h-full flex flex-col gap-y-5">
                    <div className="flex gap-y-4 flex-col md:flex-row gap-x-4 md:gap-y-0">
                        <SelectListFilter
                            options={categories}
                            select={categoriesFilter}
                            setSelect={setCategoriesFilter}
                            label="Catégories"
                            placeholder="Catégories"
                            isMulti={true}
                            callBackAction={() => setCurrentPage(1)}
                        />
                        <SelectListFilter
                            options={STATUS}
                            select={statusFilter}
                            setSelect={setStatusFilter}
                            label="Status"
                            placeholder="Status"
                            isMulti={false}
                            callBackAction={() => setCurrentPage(1)}
                        />
                    </div>
                    <Transition>
                        <div className="block max-w-full h-[50vh]">
                            <div className="mb-16 flex flex-col gap-y-10">
                                <UseTableWithColumns
                                    columns={columns}
                                    data={data.data}
                                    sort={sort}
                                    setSort={setSort}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    count={data.count}
                                />
                            </div>
                        </div>
                    </Transition>
                </div>
            )}
        </>
    );
};

export default TableReports;
