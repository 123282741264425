export const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/;
export const emailRegex = /^.*(?=.{8,})[\w.-]+@[\w.-]+[.][a-zA-Z0-9]+$/;
export const phoneRegex = /(\b(0041|0)|\B\+41)(\s?\(0\))?(\s)?[1-9]{2}(\s)?[0-9]{3}(\s)?[0-9]{2}(\s)?[0-9]{2}\b/;
export const userNameRegex = /^[-_0-9a-zA-Z]*$/;
export const titleRegex = /^[-a-zA-ZÀ-ÿ0-9:\b]*$/;
export const descriptionRegex = /^[-a-zA-ZÀ-ÿ0-9.,;\b]*$/;
export const numberOnlyRegex = /^[0-9]*$/;

// Required pattern
export const captionRequired = "Ce champ ne peut être vide"

// Caption pattern
export const captionPatternName =
	"Caractères spéciaux et chiffres non-autorisés";
export const captionPatternEmail = "Veuillez saisir un email valide";
export const captionPatternPhone = "Veuillez saisir un numéro de téléphone valide";
export const captionPatternUserName =
	"Lettres minuscules et chiffres uniquement";
export const captionPatternTitle =
	"Caractères spéciaux et chiffres non-autorisés";
export const captionPatternDescription =
	"Caractères spéciaux non-autorisés";
export const captionPatternDateEnd = "La date ne peut être antérieur";
export const captionPatternNumber = "Ne peut contenir que des chiffres";

// News caption patteen
export const captionPatternNewsmin = "Doit contenir au moins une phrase";

// Password caption pattern
export const captionPatternPasswordMin = "Doit contenir au moins 8 caractères";
export const captionPatternPasswordMax = "Ne peut excéder 16 caractères";
export const captionPatternPasswordUpper = "Doit contenir une majuscule";
export const captionPatternPasswordLower = "Doit contenir une minuscule";
export const captionPatternPasswordNumber = "Doit contenir un chiffre";
export const captionPatternPasswordMisc = "Doit contenir un caractère spécial";
export const captionPatternPasswordCheck = "Les mots de passe ne correspondent pas";
