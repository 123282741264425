import {
	CleaningServices,
	Construction,
	DeviceThermostat, Light,
	LocalParking,
	LocationCity, MoreHoriz,
	Lock, Event, Delete, Directions, Assignment, LocalFlorist, QuestionMark, Deck, Nature,
	AdminPanelSettings
} from "@mui/icons-material";

const icons = {
	cleanliness: CleaningServices,
	events: Event,
	lighting: Light,
	other: MoreHoriz,
	parking: LocalParking,
	security: Lock,
	urban_furnitures: LocationCity,
	winter_maintenance: DeviceThermostat,
	worksites: Construction,
	waste: Delete,
	public_spaces_roads: Directions,
	general_administration: Assignment,
	flowers: LocalFlorist,
	graveyard: QuestionMark,
	pick_your_own_zones: LocalFlorist,
	public_parks: Deck,
	trees: Nature,
	internal_report: AdminPanelSettings
};

export const slugToIcon = {
	"/": 'dashboard',
	agenda: 'calendar_today',
	cleanliness: 'cleaning_services',
	dashboard: 'dashboard',
	delete:'delete',
	directory: 'view_list',
	events: 'event',
	flowers: 'local_florist',
	forum: 'forum',
	general_administration: 'assignment',
	graveyard: 'question_mark',
	job: 'work',
	lighting: 'light',
	message: 'message',
	news: 'library_books',
	notifications: 'notifications',
	other: 'more_horiz',
	parking: 'local_parking',
	pick_your_own_zones: 'local_florist',
	public_parks: 'deck',
	public_spaces_roads: 'directions',
	read: 'drafts',
	reports: 'warning',
	security: 'lock',
	survey: 'assignment',
	trees: 'nature',
	unread: 'markunread',
	urban_furnitures: 'location_city',
	users: 'supervised_user_circle',
	waste: 'delete',
	warning: 'warning',
	winter_maintenance: 'device_thermostat',
	worksites: 'construction',
	internal_report: 'admin_panel_settings'
}

export const formatStatus = (status) => {
	switch (status) {
		case "new":
			return "Nouveau";
		case "ongoing":
			return "En cours";
		case "over":
			return "Terminé";
		case "archived":
			return "Archivé";
		default:
			return "Inconnu";
	}
};

export const formatRole = (role) => {
	switch (role) {
		case "ROLE_ADMIN":
		case "admin":
			return "Administrateur";
		case "ROLE_MANAGER":
		case "manager":
			return "Manager";
		case "ROLE_ASSOCIATE":
		case "associate":
			return "Associé";
		case "ROLE_CITIZEN":
		case "citizen":
			return "Citoyen";
		default:
			return "Utilisateur";
	}
};

export const formatDateString = (date) => {
	return new Date(date).toLocaleDateString("fr", {
		year: "numeric",
		month: "long",
		day: "numeric",
	});
};

export const formatTime = (dateString) => {
	const date = new Date(dateString);
	return date.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" });
  };

export const transformCategories = (data) => {
	return data.map(({ name, slug }, i) => {
		const IconComponent = icons[slug];
		return {
			id: i,
			label: name,
			value: slug,
			icon: <IconComponent sx={{ fontSize: 16 }} />,
		};
	});
};

export const getColorByPrivacy = (value) => {
	switch (value) {
		case false:
			return "border border-privacy-private text-privacy-private font-semibold bg-privacy-private/[.12] tracking-tight opacity-75";
		case true:
			return "border border-privacy-public text-privacy-public font-semibold bg-privacy-public/[.12] tracking-tight opacity-75";
		default:
			return null;
	}
};
