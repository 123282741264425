import { useContext, useMemo, useState } from "react";
import { Icon } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import {
    useMutationDeleteNotification,
    useMutationReadNotification,
    useQueryNotifications,
    useQueryPlugins
} from "../../queries";
import { ActionHeader, EditTableButton, RemoveButton } from "../../components";
import { Loader, Transition } from "../../utils";
import { formatDateString, slugToIcon } from "../../config/formats";
import { UseTableWithColumns } from "../../hooks";
import { NotificationContext } from "../../contexts/NotificationsContext";

const NotificationsPage = () => {
    const { setUnreadNotifications } = useContext(NotificationContext);
    const queryClient = useQueryClient();
    const { mutate: markAsRead } = useMutationReadNotification();
    const { mutate: deleteNotification } = useMutationDeleteNotification();
    
    const [currentPage, setCurrentPage] = useState(1);
    const [sort, setSort] = useState({ direction: "asc", accessor: "id" });

    const { isLoading, data, error } = useQueryNotifications(currentPage, sort);
    const { isLoading: isPluginsLoading, data: plugins = [] } = useQueryPlugins();

    const columns = useMemo(() => [
        {
            accessor: "action",
            Cell: ({ row: { original } }) => (
                <EditTableButton
                    id={original.id}
                    navigation={`${original.resource}/brief`}
                    onClick={() => {
                        markAsRead({ id: original.id, read: true });
                        if (!original.read) {
                            setUnreadNotifications(prevCount => Math.max(0, prevCount - 1));
                        }
                    }}
                />
            ),
        },
        {
            Header: "Lu/Non lu",
            accessor: "read",
            sortDirection: sort.accessor === "read" ? sort.direction : "none",
            Cell: ({ value }) => (
                <div className="flex items-center">
                    <Icon sx={{ fontSize: 16 }}>{slugToIcon[value ? "read" : "unread"]}</Icon>
                </div>
            )
        },
        {
            Header: "Date",
            accessor: "not_created",
            sortDirection: sort.accessor === "not_created" ? sort.direction : "none",
            Cell: ({ value }) => formatDateString(value),
        },
        {
            Header: "Module",
            accessor: "category",
            sortDirection: sort.accessor === "category" ? sort.direction : "none",
            Cell: ({ value }) => {
                const plugin = plugins.find(p => p.value === value);
                return (
                    <div className="flex items-center gap-x-2">
                        <Icon sx={{ fontSize: 16 }}>{slugToIcon[value]}</Icon>
                        <span className="text-textNeutral-dark dark:text-textNeutral-light">
                            {plugin?.label || "Unknown"}
                        </span>
                    </div>
                );
            },
        },
        {
            Header: "Sujet",
            accessor: "title",
            sortDirection: sort.accessor === "title" ? sort.direction : "none",
            Cell: ({ value }) => <span className="text-textNeutral-dark">{value}</span>,
        },
        {
            accessor: "delete",
            Cell: ({ row: { original } }) => (
                <div className="relative z-10">
                    <RemoveButton
                        onClick={() => deleteNotification(original.id, {
                            onSuccess: () => {
                                queryClient.invalidateQueries("getNotifications");
                                queryClient.invalidateQueries("countUnread");
                            }
                        })}
                    />
                </div>
            ),
        },
    ], [sort, plugins, markAsRead, setUnreadNotifications, deleteNotification, queryClient]);

    if (isLoading || isPluginsLoading) return <Loader />;
    if (error) return <div className="text-red-500">Failed to load notifications.</div>;
    if (!data || !data.data || data.data.length === 0) return <div className="text-gray-500 text-center mt-4">Pas de notifications</div>;

    return (
        <>
            <ActionHeader icon="notifications" title="Notifications" hasReturn={false} />
            <Transition>
                <div className="block max-w-full h-[50vh]">
                    <div className="mb-16 flex flex-col gap-y-10">
                        <UseTableWithColumns
                            columns={columns}
                            data={data.data}
                            sort={sort}
                            setSort={setSort}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            count={data.count}
                        />
                    </div>
                </div>
            </Transition>
        </>
    );
};

export default NotificationsPage;
