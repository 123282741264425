import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { FormButton } from "../../components/Button";
import { ErrorMessage } from "../../components/Errors";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPassword } from "../../utils/axios";
import { useNavigate } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const labelStyle =
  "mb-2 text-primary text-xs font-semibold uppercase tracking-tighter opacity-75 pl-3";
const inputStyle =
  "border-none bg-slate-200 rounded-lg px-4 py-3 text-xs transition-all outline-none";

const UpdatePasswordPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [passwordVisibleOne, setPasswordVisibleOne] = useState(false);
  const [passwordVisibleTwo, setPasswordVisibleTwo] = useState(false);
  const [focus, setFocus] = useState(false); // Focus state

  const formSchema = Yup.object().shape({
    passwordOne: Yup.string()
      .min(8, "Le mot de passe doit contenir au moins 8 caractères")
      .max(16, "Le mot de passe ne peut excéder 16 caractères")
      .matches(/(?=.*[A-Z])/, "Doit contenir au moins une majuscule")
      .matches(/(?=.*[a-z])/, "Doit contenir au moins une minuscule")
      .matches(/(?=.*[0-9])/, "Doit contenir au moins un chiffre")
      .matches(
        /(?=.*[#?!@$%^&*-])/,
        "Doit contenir au moins un caractère spécial"
      ),
    passwordTwo: Yup.string()
      .min(8, "Le mot de passe doit contenir au moins 8 caractères")
      .max(16, "Le mot de passe ne peut excéder 16 caractères")
      .matches(/(?=.*[A-Z])/, "Doit contenir au moins une majuscule")
      .matches(/(?=.*[a-z])/, "Doit contenir au moins une minuscule")
      .matches(/(?=.*[0-9])/, "Doit contenir au moins un chiffre")
      .matches(
        /(?=.*[#?!@$%^&*-])/,
        "Doit contenir au moins un caractère spécial"
      )
      .oneOf([Yup.ref("passwordOne")], "Les mots de passe ne correspondent pas"),
  });

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({ resolver: yupResolver(formSchema) });

  const handleUpdatePassword = (data, e) => {
    e.preventDefault();
    const payload = {
      password: data.passwordOne,
    };
    const token = searchParams.get("token");
    resetPassword(payload, token, (status, message) => {
      if (status === 200) {
        toast.success(message, {
          onClose: () => {
            setTimeout(() => navigate("/login"), 1000); // Delay navigation
          },
        });
      } else {
        toast.error(message);
      }
    });
  };

  return (
    <div className="bg-gradient-to-l from-primary to-secondary w-full h-[100vh] flex justify-center items-center">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="md:mx-0 md:shadow-xl w-full md:w-[860px] h-full md:h-[580px] flex flex-col justify-end md:flex-row">
        <div className="relative md:bg-gradient-to-br md:from-primary md:secondary w-full md:h-auto md:w-1/2 pb-10 md:pb-10 md:px-8 flex justify-center items-center">
          <span className="absolute hidden md:block md:bottom-5 md:right-5 uppercase text-white font-bold text-xs">
            SMAPI
          </span>
          <div className="flex justify-center items-center">
            <figure className="w-32 md:w-56">
              <img
                className="w-full h-auto"
                src={`${process.env.REACT_APP_API_URL}/uploads/logo.png`}
                alt="city img"
              />
            </figure>
          </div>
        </div>
        <div className="bg-white rounded-t-[2em] md:rounded-t-none w-full md:w-1/2 h-2/3 md:h-auto flex flex-col justify-center">
          <div className="mx-8 md:mx-12">
            <div className="flex flex-col items-start justify-center gap-y-2 mb-10 text-textNeutral-dark">
              <h2 className="font-semibold text-inherit">
                Réinitialisez votre mot de passe
              </h2>
              <div className="px-4 text-xs text-inherit opacity-75">
                <ul className="flex flex-col gap-y-1 text-xs list-disc">
                  <li>Entre 8 et 16 caractères</li>
                  <li>Une majuscule</li>
                  <li>Une minuscule</li>
                  <li>Un caractère spécial (ex. #?!@$%^&*-)</li>
                </ul>
              </div>
            </div>
            <form
              className="flex flex-col"
              onSubmit={(e) => {
                handleSubmit(handleUpdatePassword)(e);
              }}
            >
              <div className="mb-4 flex flex-col">
                <label className={labelStyle}>Nouveau mot de passe</label>
                {/* Password Input with visibility toggle */}
                <div
                  className={`relative bg-slate-200 rounded-lg transition-all ${
                    focus ? "focus-within:ring-2 focus-within:ring-primary" : ""
                  }`}
                >
                  <input
                    className={`${inputStyle} pr-10`} // Added padding-right for the icon
                    {...register("passwordOne")}
                    type={passwordVisibleOne ? "text" : "password"}
                    placeholder="Nouveau mot de passe"
                    onFocus={() => setFocus(true)} // Set focus to true on input focus
                    onBlur={() => setFocus(false)} // Set focus to false on input blur
                  />
                  <span
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    onClick={() => setPasswordVisibleOne(!passwordVisibleOne)}
                  >
                    {passwordVisibleOne ? (
                      <VisibilityOff fontSize="small" />
                    ) : (
                      <Visibility fontSize="small" />
                    )}
                  </span>
                </div>
                {errors.passwordOne ? (
                  <ErrorMessage type="pattern" message={errors.passwordOne?.message} />
                ) : null}
              </div>
              <div className="mb-4 flex flex-col">
                <label className={labelStyle}>Confirmer le nouveau mot de passe</label>
                {/* Confirm Password Input with visibility toggle */}
                <div
                  className={`relative bg-slate-200 rounded-lg transition-all ${
                    focus ? "focus-within:ring-2 focus-within:ring-primary" : ""
                  }`}
                >
                  <input
                    className={`${inputStyle} pr-10`} // Added padding-right for the icon
                    {...register("passwordTwo")}
                    type={passwordVisibleTwo ? "text" : "password"}
                    placeholder="Confirmer le nouveau mot de passe"
                    onFocus={() => setFocus(true)} // Set focus to true on input focus
                    onBlur={() => setFocus(false)} // Set focus to false on input blur
                  />
                  <span
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    onClick={() => setPasswordVisibleTwo(!passwordVisibleTwo)}
                  >
                    {passwordVisibleTwo ? (
                      <VisibilityOff fontSize="small" />
                    ) : (
                      <Visibility fontSize="small" />
                    )}
                  </span>
                </div>
                {errors.passwordTwo ? (
                  <ErrorMessage type="pattern" message={errors.passwordTwo?.message} />
                ) : null}
              </div>
              <div>
                <div className="mt-12 md:mt-8 flex justify-center">
                  <FormButton caption="Envoyer" isValid={isValid} />
                </div>
              </div>
              <div className="mt-4 flex justify-center">
                <p className="text-xs text-textNeutral-dark">
                  Vous possédez déjà un compte ?
                  <NavLink className="ml-1 text-primary text-xs" to="/login">
                    Connectez-vous
                  </NavLink>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePasswordPage;