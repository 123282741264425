import { useNavigate, useOutletContext } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

// Queries
import { useMutationJobUpdate } from "../../queries";

// Components
import {
  SelectInput,
  DateInput,
  NavigationHeader,
  TextInput,
  FormButton,
  EditorQuill,
} from "../../components";

// Config
import { CONTRACT_TYPE, WORK_TIME } from "../../config/config";
import { formSchemaAddJob } from "../../config/yupconfig";

// Misc
import { Transition } from "../../utils/";

const JobPageEdit = () => {
  const jobData = useOutletContext();
  const { mutate: updateJob } = useMutationJobUpdate(jobData.id);
  const navigate = useNavigate();

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(formSchemaAddJob),
    defaultValues: {
      ...jobData,
      workTime:  WORK_TIME.find(i => i.value === jobData?.workTime)?.value || "",
      contractType: CONTRACT_TYPE.find(i => i.value === jobData?.contractType)?.value,
    },
  });

  const onSubmit = async (data) => {
    
    const updatedJob = {
      ...data,
      workTime:  WORK_TIME.find(i => i.value === data?.workTime)?.value || jobData.workTime,
      contractType: WORK_TIME.find(i => i.value === data?.workTime)?.value || jobData.contractType,
    };
    updateJob(updatedJob, {
      onSuccess: () => {
        toast.success("L'offre a été modifiée");
        navigate("/job");
      },
      onError: () => toast.error("Une erreur est survenue"),
    });
  };

  return (
    <Transition>
      <NavigationHeader title="Emplois" subTitle={jobData.entrepriseName} returnUrl="/job" />
      <div className="w-full lg:w-[46rem] flex flex-col gap-y-5">
        <form className="flex flex-col gap-y-5" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-y-8">
            <div className="flex flex-col md:flex-row md:justify-between gap-y-8 md:gap-y-0 gap-x-5">
              <div className="flex flex-col">
                <DateInput
                  id="start-time"
                  label="Date de commencement"
                  register={{ ...register("startTime") }}
                  error={errors?.startTime}
                />
              </div>
              <div className="flex flex-col">
                <SelectInput
                  id="contract-type"
                  label="Type de contrat"
                  items={CONTRACT_TYPE}
                  register={{ ...register("contractType") }}
                  error={errors?.contractType}
                  
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:justify-between gap-y-8 md:gap-y-0 gap-x-5">
              <div className="flex flex-col">
                <SelectInput
                  id="work-time"
                  label="Temps de travail"
                  items={WORK_TIME}
                  register={{ ...register("workTime") }}
                  error={errors?.workTime}
                />
              </div>
              <div className="flex flex-col">
                <TextInput
                  id="entreprise-name"
                  label="Nom de l'entreprise"
                  register={{ ...register("entrepriseName") }}
                  error={errors?.entrepriseName}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:justify-between gap-y-8 md:gap-y-0 gap-x-5">
              <div className="flex flex-col">
                <TextInput id="title" label="Titre du poste" register={{ ...register("title") }} error={errors?.title} />
              </div>
              <div className="flex flex-col">
                <TextInput id="address" label="Lieu" register={{ ...register("address") }} error={errors?.address} />
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:justify-between gap-y-8 md:gap-y-0 gap-x-5">
              <div className="w-full flex flex-col">
                <Controller
                  control={control}
                  name="description"
                  render={({ field: { onChange, value } }) => (
                    <EditorQuill label="Description du poste" value={value} onChange={onChange} error={errors?.description} />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="mt-5 w-full flex flex-col gap-y-3 md:flex-row md:justify-end md:items-center md:gap-x-4 md:gap-y-0">
            <FormButton caption="Modifier l'offre" isValid={isValid} />
          </div>
        </form>
      </div>
    </Transition>
  );
};

export default JobPageEdit;