// External librairies
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

// Queries
import { useMutationProfile } from "../../queries";

// Components
import {
	Avatar,
	TextInput,
	DateInput,
	CheckboxInput,
	FormButton,
} from "../../components";

// Config
import { formatDateString } from "../../config/formats";
import { formSchemaUser } from "../../config/yupconfig";

// Utils
import { Transition } from "../../utils";

// Icons
import { ModeEdit } from "@mui/icons-material";

const ProfilePageEdit = () => {
	const userData = useOutletContext();
	const { id } = useParams();
	
	const {mutate: mutateProfile} = useMutationProfile(id);

	const {
		register,
		formState: { errors, isValid },
		handleSubmit,
		reset,
		watch,
	} = useForm({
		mode: "onChange",
		resolver: yupResolver(formSchemaUser),
	});

	const watchPasswordChange = watch("passwordChange");

	useEffect(() => {
		let defaultValues = {...userData};
		reset({ ...defaultValues });
	}, [reset, userData]);

	const onSubmit = (data, e) => {
		e.preventDefault();
		let payload = {...data}
		if(data.passwordChange) { payload.password = data.passwordOne }
		mutateProfile(payload, {
            onSuccess: () => toast.success("L'utilisateur a été mis à jour !"),
            onError: () => toast.error("Une erreur est survenue")
        });
	};

	return (
		<div className="mb-12 w-full lg:w-[52rem] h-full pt-10 px-14">
			<Transition>
				<form
					className="flex flex-col items-between gap-y-10"
					onSubmit={(e) => {
						handleSubmit(onSubmit)(e);
					}}
				>
					<div className="mb-8 flex flex-col gap-y-8 lg:flex-row lg:gap-x-8 lg:justify-between">
						<div className="flex flex-col gap-y-5 lg:flex-row lg:gap-x-8 lg:gap-y-0">
							<figure className="relative rounded-lg w-32 h-32 shadow-custom">
								<Avatar
									className="rounded-lg"
									userAvatar={userData.avatar}
								/>
								<span className="z-2 rounded-full w-8 h-8 flex justify-center items-center absolute -bottom-2 -right-2 text-textNeutral-light bg-primary">
									<ModeEdit sx={{ fontSize: 16 }} />
								</span>
							</figure>
							<div className="flex flex-col justify-center gap-y-3">
								<div className="flex flex-col gap-x-1">
									<p className="text-textNeutral-dark">
										{userData?.first_name}{" "}
										<span className="font-semibold">
											{userData?.last_name}
										</span>
									</p>
									<span className="text-textNeutral-dark text-xs">
										{userData?.email}
									</span>
								</div>
								<p className="flex items-center gap-x-1 text-textNeutral-dark/[.65] text-xs">
									<span className="text-inherit">
										Inscription:{" "}
									</span>
									<span className="text-inherit">
										{formatDateString(
											userData?.use_created
										)}
									</span>
								</p>
							</div>
						</div>
						<div className="flex items-center">
							<CheckboxInput
								label="Changer le mot de passe"
								register={{
									...register("passwordChange"),
								}}
							/>
						</div>
					</div>
					<div className="flex flex-col gap-y-8 lg:flex-row lg:justify-between gap-x-4">
						<TextInput
							id="first_name"
							label="Prénom"
							register={{
								...register("first_name", {
									required: true,
								}),
							}}
							error={errors?.first_name}
						/>
						<TextInput
							id="last_name"
							label="Nom de famille"
							register={{
								...register("last_name", {
									required: true,
								}),
							}}
							error={errors?.last_name}
						/>
					</div>
					<div className="flex flex-col gap-y-8 lg:flex-row lg:justify-between gap-x-4">
						<TextInput
							id="email"
							label="Email"
							register={{
								...register("email", {
									required: true,
								}),
							}}
							error={errors?.email}
						/>
						<TextInput
							id="username"
							label="Nom d'utilisateur"
							register={{
								...register("username", {
									required: true,
								}),
							}}
							error={errors?.username}
						/>
					</div>
					<div className="flex flex-col gap-y-8 lg:flex-row lg:justify-between gap-x-4">
						<DateInput
							id="birthday"
							label="Date de naissance"
							register={{
								...register("birthday", {
									required: true,
								}),
							}}
							error={errors?.birthday}
						/>
						<div></div>
					</div>
					<div className="flex flex-col gap-y-8 lg:flex-row lg:justify-between gap-x-4">
						{watchPasswordChange && (
							<>
								<TextInput
									id="password-one"
									label="Nouveau mot de passe"
									type="password"
									register={{
										...register("passwordOne"),
									}}
									error={errors?.passwordOne}
								/>
								<TextInput
									id="password-two"
									label="Confirmer le nouveau mot de passe"
									type="password"
									register={{
										...register("passwordTwo"),
									}}
									error={errors?.passwordTwo}
								/>
							</>
						)}
					</div>
					<div className="mt-4 w-full flex md:justify-end">
						<FormButton
							caption="Mettre à jour"
							icon="update"
							isValid={isValid}
						/>
					</div>
				</form>
			</Transition>
		</div>
	);
};

export default ProfilePageEdit;
