// External Librairies
import { Navigate } from "react-router-dom";
import { refreshToken, validateToken } from "../axios";
import React, { useEffect, useState } from 'react';

const PrivateRoutes = ({ children }) => {
    const [isValidToken, setIsValidToken] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkToken = async () => {
            const user = JSON.parse(localStorage.getItem('user'));
			
            const token = user?.accessToken;
			const refreshToken = user?.refreshToken

            if (token) {
                const valid = await validateToken(token,refreshToken);
				
                setIsValidToken(valid.valid);
            }
            setIsLoading(false);
        };

        checkToken();
    }, []);

    if (isLoading) {
        return <div>Loading...</div>; // Show a loading indicator while checking the token
    }

    return isValidToken ? <>{children}</> : <Navigate to="/login" replace />;
};

export default PrivateRoutes;
