import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useOutletContext, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import {useMutationUpdateUser, useQueryCategories, useQueryPlugins} from "../../queries";

import {
	TextInput,
	DateInput,
	RadioInput,
	CheckboxInput,
	SelectMultiInput,
} from "../../components/Input";
import { FormButton } from "../../components/Button";

import { ROLES } from "../../config/config";
import { formatDateString } from "../../config/formats";
import { formSchemaUser } from "../../config/yupconfig";

import { ModeEdit } from "@mui/icons-material";

import { Transition } from "../../utils/react-router";
import { Avatar } from "../../components/Avatar";

const UsersPageEdit = () => {
	const userData = useOutletContext();
	const { id } = useParams();
	const { data: categories } = useQueryCategories();
	const {data: plugins} = useQueryPlugins();
	const { mutate: updateUser } = useMutationUpdateUser(id)

	const navigate = useNavigate();

	const {
		register,
		formState: { errors, isValid },
		handleSubmit,
		reset,
		watch,
		control,
	} = useForm({
		mode: "onChange",
		resolver: yupResolver(formSchemaUser),
		defaultValues: {
			Plugins: [] 
		}
	});

	const watchPasswordChange = watch("passwordChange");
	const watchUserRole = watch("role");
	const watchUserPlugin = watch("Plugins")

	useEffect(() => {
		let defaultValues = {...userData};
		defaultValues.Plugins = userData.Plugins.map((p) => ({ value: p.slug, label: p.fr }))
		defaultValues.Categories = userData.Categories.map((c) => ({ value: c.slug, label: c.name }))
		reset({ ...defaultValues });
	}, [reset, userData]);

	const onSubmit = (data, e) => {
		e.preventDefault();
		let payload = {...data}



		if (["associate", "manager"].includes(data.role)) {
			const cat = data.Categories;
			const plugins = data.Plugins;

			const formatedCat = []
			const formatedPlugins = plugins?.map((p) => p.value)

			//remove associated categories if no reports plugin
			if (formatedPlugins.some(p => p === "reports")) {
				cat?.forEach((c) => formatedCat.push(c.value))
			}

			payload.Categories = formatedCat;
			payload.Plugins = formatedPlugins;
		}

		if(data.passwordChange) { payload.password = data.passwordOne }
		updateUser(payload, {
			onSuccess: () => {
				toast.success("L'utilisateur a été mis à jour !");
				navigate(`/users/${id}/brief`);
			},
			onError: (err) => {
				toast.warning(err.message);
			}
		})
	};

	return (
		<div className="w-full lg:w-[52rem] h-full pt-10 px-14">
			<Transition>
				<form
					className="flex flex-col items-between gap-y-10"
					onSubmit={(e) => {
						handleSubmit(onSubmit)(e);
					}}
				>
					<div className="mb-8 flex flex-col gap-y-8 lg:flex-row lg:gap-x-8 lg:justify-between">
						<div className="flex flex-col gap-y-5 lg:flex-row lg:gap-x-8 lg:gap-y-0">
							<figure className="relative rounded-lg w-32 h-32 shadow-custom">
								<Avatar
									className="rounded-lg"
									userAvatar={userData.avatar}
								/>
								<span className="z-2 rounded-full w-8 h-8 flex justify-center items-center absolute -bottom-2 -right-2 text-textNeutral-light bg-primary">
									<ModeEdit sx={{ fontSize: 16 }} />
								</span>
							</figure>
							<div className="flex flex-col justify-center gap-y-3">
								<div className="flex flex-col gap-x-1">
									<p className="text-textNeutral-dark">
										{userData?.first_name}{" "}
										<span className="font-semibold">
											{userData?.last_name}
										</span>
									</p>
									<span className="text-textNeutral-dark text-xs">
										{userData?.email}
									</span>
								</div>
								<p className="flex items-center gap-x-1 text-textNeutral-dark/[.65] text-xs">
									<span className="text-inherit">
										Inscription:{" "}
									</span>
									<span className="text-inherit">
										{formatDateString(
											userData?.use_created
										)}
									</span>
								</p>
							</div>
						</div>
						<div className="flex items-center">
							<CheckboxInput
								label="Changer le mot de passe"
								register={{
									...register("passwordChange"),
								}}
							/>
						</div>
					</div>
					<div className="flex flex-col gap-y-8 lg:flex-row lg:justify-between gap-x-4">
						<TextInput
							id="first_name"
							label="Prénom"
							register={{
								...register("first_name", {
									required: true,
								}),
							}}
							error={errors?.first_name}
						/>
						<TextInput
							id="last_name"
							label="Nom de famille"
							register={{
								...register("last_name", {
									required: true,
								}),
							}}
							error={errors?.last_name}
						/>
					</div>
					<div className="flex flex-col gap-y-8 lg:flex-row lg:justify-between gap-x-4">
						<TextInput
							id="email"
							label="Email"
							register={{
								...register("email", {
									required: true,
								}),
							}}
							error={errors?.email}
						/>
						<TextInput
							id="username"
							label="Nom d'utilisateur"
							register={{
								...register("username", {
									required: true,
								}),
							}}
							error={errors?.username}
						/>
					</div>
					<div className="flex flex-col gap-y-8 lg:flex-row lg:justify-between gap-x-4">
						<DateInput
							id="birthday"
							label="Date de naissance"
							register={{
								...register("birthday", {
									required: true,
								}),
							}}
							error={errors?.birthday}
						/>
						{["associate", "manager"].includes(watchUserRole) && (
							<Transition>
								<Controller
									name="Plugins"
									control={control}
									render={({field}) => (
										<SelectMultiInput
											placeholder="Modules"
											options={plugins}
											field={field}
											label="Modules"
											error={
												errors?.Plugins
											}
										/>
									)}
								/>
							</Transition>
						)}
					</div>
					<div>
						{
							(watchUserPlugin && watchUserPlugin.length > 0) && (
								(watchUserPlugin.map(p => p.value).includes('reports') && ["associate", "manager"].includes(watchUserRole)) && (
									<Transition>
										<Controller
											name="Categories"
											control={control}
											render={({field}) => (
												<SelectMultiInput
													placeholder="Catégories"
													options={categories}
													field={field}
													label="Catégories"
													error={
														errors?.Categories
													}
												/>
											)}
										/>
									</Transition>
								)
							)
						}
					</div>
					<div className="flex flex-col gap-y-8 lg:flex-row lg:justify-between gap-x-4">
						{watchPasswordChange && (
							<>
								<TextInput
									id="password-one"
									label="Nouveau mot de passe"
									type="password"
									register={{
										...register("passwordOne"),
									}}
									error={errors?.passwordOne}
								/>
								<TextInput
									id="password-two"
									label="Confirmer le nouveau mot de passe"
									type="password"
									register={{
										...register("passwordTwo"),
									}}
									error={errors?.passwordTwo}
								/>
							</>
						)}
					</div>
					<div className="xs:w-full md:w-[16rem] lg:w-full grid grid-cols-1 lg:grid-cols-2 gap-y-6 gap-x-3 md:gap-x-16">
						{ROLES &&
							ROLES.filter((v) => v.slug !== "all").map(
								(role) => {
									return (
										<div key={role.id}>
											<RadioInput
												id={role.slug}
												label={role.name}
												register={{
													...register("role"),
												}}
												value={role.slug}
												bg={role.bg}
												bgFade={role.bgFade}
												border={role.border}
												color={role.color}
												ring={role.ring}
											/>
										</div>
									);
								}
							)}
					</div>
					<div className="mt-4 w-full flex md:justify-end">
						<FormButton
							caption="Mettre à jour"
							icon="update"
							isValid={isValid}
						/>
					</div>
				</form>
			</Transition>
		</div>
	);
};

export default UsersPageEdit;
