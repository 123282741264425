// External libraries
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { KeyboardBackspace } from '@mui/icons-material';
import classNames from 'classnames';
import Popup from "reactjs-popup";

const NavigationHeader = ({
    title,
    subTitle,
    navData,
    hasReturn = true,
    returnUrl,
    canDelete,
    handleDelete,
    extraButton // ✅ Added extraButton prop
}) => {
    const location = useLocation();
    return (
        <header
            className={classNames(
                'mt-6 mb-12 w-full flex flex-col gap-y-8 lg:flex-row lg:justify-between lg:items-center lg:gap-y-0 border-b border-bgNeutral-dark/[.09] pb-8',
                navData && 'gap-y-8'
            )}
        >
            <div className="flex items-center gap-x-5">
                {hasReturn && (
                    <div>
                        <NavLink to={`${returnUrl}`}>
                            <button
                                className="rounded-lg flex justify-center items-center py-2 px-3 text-textNeutral-light bg-primary transition-all hover:opacity-75">
                                <KeyboardBackspace sx={{ fontSize: 22 }} />
                            </button>
                        </NavLink>
                    </div>
                )}

                <div className="flex flex-col gap-y-1 md:flex-row md:items-center md:gap-x-3 md:gap-y-0">
                    <h2>{title}</h2>
                    {subTitle && (
                        <>
                            <span className="hidden md:block">-</span>
                            <span className="font-light text-sm text-textNeutral-dark">
                                {subTitle}
                            </span>
                        </>
                    )}
                </div>
            </div>

            <div className="flex items-center gap-x-4"> 
                {navData && (
                    <nav>
                        <ul className="flex items-center gap-x-3">
                            {navData.map((n) => (
                                <div key={n.id}>
                                    {!n.isExtra ? (
                                        <li className="flex justify-center items-center">
                                            <NavLink
                                                className={classNames(
                                                    'rounded-lg flex justify-between items-center px-6 py-3 text-sm font-semibold whitespace-nowrap transition-all hover:opacity-[.85]',
                                                    location.pathname.includes(n.route)
                                                        ? 'text-primary bg-primary/[.16]'
                                                        : 'text-textNeutral-dark/[.80] font-medium bg-bgNeutral-dark/[.08]'
                                                )}
                                                to={n.route}
                                            >
                                                {n.label}
                                            </NavLink>
                                        </li>
                                    ) : (
                                        <li className="ml-5 border-l-[1.2px] border-bgNeutral-dark/[.12] flex justify-center items-center pl-8">
                                            <NavLink
                                                className={classNames(
                                                    'rounded-lg flex justify-between items-center px-6 py-3 text-sm font-semibold whitespace-nowrap transition-all hover:opacity-[.85]',
                                                    location.pathname.includes(n.route)
                                                        ? 'text-textNeutral-light bg-slate-600'
                                                        : 'text-textNeutral-dark/[.80] font-medium bg-slate-300'
                                                )}
                                                to={n.route}
                                            >
                                                {n.label}
                                            </NavLink>
                                        </li>
                                    )}
                                </div>
                            ))}
                            
                            {// delete button
                                canDelete && (
                                    <Popup
                                        trigger={
                                            <button
                                                className='rounded-lg flex justify-between items-center px-6 py-3 text-sm font-semibold whitespace-nowrap transition-all hover:opacity-[.85] text-textNeutral-light bg-rose-400'>
                                                Supprimer
                                            </button>
                                        }
                                        modal
                                        contentStyle={{
                                            "display": "flex",
                                            "flexDirection": "column",
                                            "borderRadius": "0.5rem",
                                            "width": "30%"
                                        }}
                                    >
                                        {close => (
                                            <div className="flex flex-col gap-y-8 p-6 bg-bgNeutral-light overflow-auto">
                                                <div className="text-lg text-center">
                                                    Êtes vous sûr de vouloir supprimer ?
                                                </div>
                                                <ul className="flex self-center items-center gap-x-3">
                                                    <li className="rounded-lg flex justify-center items-center gap-x-4 transition-all hover:opacity-[.85] border-0 bg-primary">
                                                        <button onClick={close}
                                                                className="flex justify-between items-center gap-x-4 px-6 py-3">
                                                            <span
                                                                className="text-sm font-semibold whitespace-nowrap text-textNeutral-light">
                                                                Annuler
                                                            </span>
                                                        </button>
                                                    </li>
                                                    <li className="rounded-lg flex justify-center items-center gap-x-4 transition-all hover:opacity-[.85] border-0 bg-rose-400">
                                                        <button
                                                            onClick={_ => {
                                                                handleDelete()
                                                                close()
                                                            }}
                                                            className="flex justify-between items-center gap-x-4 px-6 py-3">
                                                            <span
                                                                className="text-sm font-semibold whitespace-nowrap text-textNeutral-light">
                                                                Supprimer
                                                            </span>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        )}
                                    </Popup>
                                )
                            }
                        </ul>
                    </nav>
                )}

             
                {extraButton && <div>{extraButton}</div>}
            </div>
        </header>
    );
};

export default NavigationHeader;