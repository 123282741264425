import React from "react";
import { useParams, Outlet } from "react-router-dom";
import { useQueryComments, useQueryReport } from "../../queries";
import { NavigationHeader, NoData } from "../../components";
import { Loader } from "../../utils";
import { jsPDF } from "jspdf";
import { formatTime, formatDateString } from "../../config/formats";
import { IMAGES_URL } from "../../config/config";
const ReportsPageSelect = () => {
    const { id } = useParams();
    const { isLoading, data } = useQueryReport(id);
    const { data: dataComments } = useQueryComments(id);

    // Define export function at this level
    const exportAsPDF = () => {
		const doc = new jsPDF();

        // --- Set Font and Styling ---
        doc.setFont("helvetica", "normal");
        doc.setFontSize(12);

        // --- Add Title ---
        doc.setFontSize(14);
        doc.text(data?.title || "Untitled Report", 105, 20, { align: "center" });

        // --- Add Date & Description ---
        doc.setFontSize(10);
        doc.text(`Date: ${formatDateString(data?.rep_created)} à ${formatTime(data?.rep_created)}`, 10, 30);
        doc.text(`Description: ${data?.description || "No description available."}`, 10, 40, { maxWidth: 180 });

        // --- Add Location ---
        doc.setFontSize(10);
        doc.text("Localisation:", 10, 50);
        doc.setTextColor(0, 123, 255);
        doc.text(`${data?.lat || "N/A"}, ${data?.lon || "N/A"}`, 30, 50);
        doc.setTextColor(0, 0, 0); // Reset text color

        let imageY = 60; // Initial Y position for images

        // --- Add Images if Available ---
        if (data?.ReportImages?.length > 0) {
            data.ReportImages.forEach((img, index) => {
                const imageUrl = img.type === "local" ? img.filename : `${IMAGES_URL}${img.filename}`;

                // Load image and add to PDF
                const imgElement = new Image();
                imgElement.src = imageUrl;
                imgElement.onload = () => {
                    doc.addImage(imgElement, "JPEG", 10, imageY, 50, 50); // X, Y, Width, Height
                    imageY += 55; // Move down for next image
                    if (index === data.ReportImages.length - 1) {
                        doc.save(`${data?.title || "report"}.pdf`);
                    }
                };
            });
        } else {            // Save the PDF if no images exist
            doc.save(`${data?.title || "report"}.pdf`);
        }
	}

    const navData = [
        { id: 0, route: "brief", label: "Aperçu", isExtra: false },
        { id: 1, route: "edit", label: "Editer", isExtra: false },
        { 
            id: 2, 
            route: "comments", 
            label: `Commentaires (${dataComments?.length ?? 0})`, 
            isExtra: false 
        },
    ];

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                !data ? (
                    <NoData caption="Aucun signalement à afficher" />
                ) : (
                    <>
                        <NavigationHeader
                            title="Incident"
                            subTitle={data?.title}
                            navData={navData}
                            returnUrl="/reports"
                            extraButton={
                                <button 
                                    onClick={exportAsPDF} 
                                     className="px-6 py-3 text-sm font-semibold text-white bg-primary rounded-lg transition-all hover:opacity-75"
                                >
                                    Exporter en PDF
                                </button>
                            }
                        />
                        <div className="mt-3 block max-w-full">
                            <Outlet context={data} />
                        </div>
                    </>
                )
            )}
        </>
    );
};

export default ReportsPageSelect;